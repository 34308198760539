import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import { AuctionDate, AuctionSearchResult } from '../types/auction';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

export const parseApiDate = (date: string) => {
  return dayjs(date);
};

export const getDaysBetweenDates = (st: Dayjs, end: Dayjs): Dayjs[] => {
  // make immutable dayjs object
  let d = st.clone();
  const dates: Dayjs[] = [];

  // check if date is before or same as end date and push to an array
  while (d.isSameOrBefore(end)) {
    dates.push(d);
    // add a day to the date to continue while loop until end date
    d = d.add(1, 'day');
  }

  return dates;
};

export const getAuctionDates = (
  st: Dayjs,
  end: Dayjs,
  availableDates?: AuctionSearchResult['auctionDates'],
): AuctionDate[] => {
  const dates: AuctionDate[] = [];

  const VALUE_FORMAT = 'YYYY-MM-DD';

  // check if there are available dates and format them to value format for comparison
  let selectableDates: AuctionSearchResult['auctionDates'] = [];
  if (availableDates && availableDates.length > 0)
    selectableDates = availableDates.map((date) => parseApiDate(date).format(VALUE_FORMAT));

  getDaysBetweenDates(st.startOf('day'), end.startOf('day')).forEach((dt) => {
    const value = dt.format(VALUE_FORMAT);
    dates.push({
      value,
      dow: dt.isToday() ? 'Today' : dt.format('ddd'),
      date: dt.format('DD MMM'),
      // is selectable in calendar
      isActive: Boolean(selectableDates && selectableDates.includes(value)),
    });
  });

  return dates;
};

export default dayjs;
